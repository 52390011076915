import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";
import PropTypes from "prop-types";
import AmountContainer from "app/pages/.shared/AmountContainer";
import { PAYMENT_OPTIONS } from "app/constants";
import LoaderBar from "app/pages/.shared/LoaderBar/LoaderBar";

import "./Total.scss";

const Total = ({ total, isPagePayment = false, paymentMode, isLoading }) => {
	const is1xPaymentMode = paymentMode === PAYMENT_OPTIONS["1x"];

	const totalAmountClass = classNames({
		"total__value total__price-detail-item": true,
		"total__value--small": !is1xPaymentMode && isPagePayment,
	});

	return (
		<div className="total">
			<div className="total__label">
				<FormattedMessage id="price.detail.total.label" />
				{isPagePayment && (
					<div className="total__label--note">
						(<FormattedMessage id="general.all.options.included.label" />)
					</div>
				)}
			</div>
			<div className={totalAmountClass}>
				{isLoading && isPagePayment ? (
					<LoaderBar width={80} />
				) : (
					<AmountContainer amount={total} />
				)}
			</div>
		</div>
	);
};

Total.propTypes = {
	total: PropTypes.number,
	isPagePayment: PropTypes.bool,
	paymentMode: PropTypes.number,
	isLoading: PropTypes.bool,
};

export default React.memo(Total);
