import PropTypes from "prop-types";
import React from "react";
import { FormattedDate } from "react-intl";
import "./period.scss";

const Period = ({ fromDate, endDate, format, prefix, suffix }) => {
	const prefixNode = prefix ? <span className="period__prefix">{prefix}</span> : false;

	const fromDateNode = fromDate ? (
		<div className="period__start">
			{prefixNode}
			<span className="period__date">
				<FormattedDate timeZone="UTC" value={new Date(fromDate)} {...format} />
			</span>
		</div>
	) : (
		false
	);

	const endDateNode = endDate ? (
		<div className="period__end">
			<span className="period__suffix">{suffix}</span>

			<span className="period__date">
				<FormattedDate timeZone="UTC" value={new Date(endDate)} {...format} />
			</span>
		</div>
	) : (
		false
	);

	return (
		<div className="period">
			{fromDateNode}
			{endDateNode}
		</div>
	);
};

Period.propTypes = {
	fromDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	endDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	format: PropTypes.object,
	suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Period;
