import React from "react";
import RelativeLink from "../RelativeLink";
import PropTypes from "prop-types";
import { PRODUCTS_VISIBILITY, STRATEGY } from "app/constants";
import { stringify } from "qs";
import isEmpty from "lodash/isEmpty";
import { strategyShape } from "app/utils/propTypes";
import { isAbsoluteUrl } from "app/utils/urlDataExtractor";
import { useLocation } from "react-router-dom";
import { parseQueries } from "app/utils/routerUtils";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";

const ProductLink = ({
	handleProductClick,
	children,
	openOnNewTab,
	productsVisibility,
	productUri,
	strategy,
	resolution,
	source,
}) => {
	const isDesktop = resolution === RESOLUTION.LARGE || resolution === RESOLUTION.XLARGE;
	const newTabLink = openOnNewTab
		? {
				target: "_blank",
				rel: "noopener noferrer",
		  }
		: {};

	let productUrl = productUri;
	if (productUri && !isAbsoluteUrl(productUri) && !productUri.startsWith("/")) {
		productUrl = "/" + productUri;
	}
	const { search: searchLocation } = useLocation();
	const { code: codeMerch } = parseQueries(searchLocation);
	const to = {
		pathname: `${productUrl}`,
	};

	const search = {};

	// Persistence du mode preview de la listing vers la fiche produit pour la team production
	if (productsVisibility === PRODUCTS_VISIBILITY.PREVIEW) {
		search.preview = PRODUCTS_VISIBILITY.PREVIEW;
	}

	// si on ouvre dans un nouvel onglet, on doit persister le mode preview et la strategy
	if (openOnNewTab) {
		const strategyExists =
			strategy &&
			Object.keys(STRATEGY).some(key => {
				return STRATEGY[key] === strategy;
			});

		// Persistence de la strategy de la listing vers la fiche produit pour la team production
		if (strategyExists) {
			search.strategy = strategy;
		}
	}

	if (codeMerch && isDesktop) {
		search.fromMerch = codeMerch;
	}

	if (source) {
		search.source = source;
	}

	if (!isEmpty(search)) {
		to.search = stringify(search);
	}

	return (
		<RelativeLink
			design="primary"
			className="product-link"
			{...newTabLink}
			to={to}
			onClick={handleProductClick}
			data-cy={`product-cta-${productUri}`}
		>
			{children}
		</RelativeLink>
	);
};

ProductLink.propTypes = {
	handleProductClick: PropTypes.func,
	openOnNewTab: PropTypes.bool,
	productsVisibility: PropTypes.string,
	strategy: strategyShape,
	productUri: PropTypes.string,
	resolution: PropTypes.string,
	source: PropTypes.string,
};

export default React.memo(ProductLink);
