import PropTypes from "prop-types";
import React from "react";
import range from "lodash/range";
import "./room-capacity.scss";
import IconAdult from "app/pages/.shared/static/icons/IconAdult";
import IconChild from "app/pages/.shared/static/icons/IconChild";
import IconBaby from "app/pages/.shared/static/icons/IconBaby";

const RoomCapacity = ({ adultCount = 0, childCount = 0, babyCount = 0 }) => {
	const adultNode = range(0, adultCount).map(index => {
		return <IconAdult key={`adult-${index}`} width={10} height={20} />;
	});

	const childNode = range(0, childCount).map(index => {
		return <IconChild key={`child-${index}`} height={19} />;
	});

	const babyNode = range(0, babyCount).map(index => {
		return <IconBaby key={`baby-${index}`} height={20} width={20} />;
	});

	return (
		<div className="room-capacity">
			{adultNode}
			{childNode}
			{babyNode}
		</div>
	);
};

RoomCapacity.propTypes = {
	adultCount: PropTypes.number,
	childCount: PropTypes.number,
	babyCount: PropTypes.number,
};

export default RoomCapacity;
