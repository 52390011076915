import React from "react";
import PropTypes from "prop-types";
import IconInfo from "app/pages/.shared/static/icons/IconInfo";
import PopoverButton from "app/pages/.shared/Popover/PopoverButton";
import "./PopoverInfo.scss";

const PopoverInfo = ({
	popOverContent,
	popOverClassName,
	popOverContainerClassName,
	popOverButtonClassName,
	popOverContentClassname,
}) => {
	return (
		<PopoverButton
			popOverClassName={`popover-info ${popOverClassName}`}
			popOverContainerClassName={`popover-info__container ${popOverContainerClassName}`}
			popOverButtonClassName={`popover-info__button ${popOverButtonClassName}`}
			popOverPosition="below"
			closeOnBodyClick={true}
			popOverContent={
				<div className={`popover-info__content ${popOverContentClassname}`}>
					{popOverContent}
				</div>
			}
			buttonLabel={<IconInfo width={15} height={15} style={{ marginLeft: "5px" }} />}
		/>
	);
};

PopoverInfo.propTypes = {
	popOverContent: PropTypes.object,
	popOverClassName: PropTypes.string,
	popOverContainerClassName: PropTypes.string,
	popOverButtonClassName: PropTypes.string,
	popOverContentClassname: PropTypes.string,
};

PopoverInfo.defaultProps = {
	popOverClassName: "",
	popOverContainerClassName: "",
	popOverButtonClassName: "",
	popOverContentClassname: "",
};

export default PopoverInfo;
