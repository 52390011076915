import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import AmountContainer from "app/pages/.shared/AmountContainer.jsx";
import Separator from "app/pages/.shared/Separator";
import PayNowContainer from "app/pages/.shared/PayNow/PayNowContainer";
import { CollapsableQuotationPriceDetailContainerMobile } from "app/pages/Booking/Quotation/PriceDetails/QuotationPriceDetailContainer";
import { PAYMENT_OPTIONS, USABLE_CREDITS_STATUS } from "app/constants";
import Total from "app/pages/.shared/PriceSummary/Total/Total";

import "./PriceSummary.scss";
import QuotationItemsContainer from "app/pages/Booking/Quotation/PriceDetails/QuotationItemsContainer";
import ExtraPriceDetailsContainer from "app/pages/Booking/Quotation/PriceDetails/ExtraPriceDetails/ExtraPriceDetailsContainer";
import { resolutionPropType } from "app/utils/propTypes";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";

const PaymentPriceSummary = ({
	total,
	mensualite2Fois,
	isBookingEligibleForDeposit,
	paymentMode,
	mensualiteCasino,
	usableCouponsStatus,
	resolution,
}) => {
	const is1xPaymentMode = paymentMode === PAYMENT_OPTIONS["1x"];
	const is2xPaymentMode = paymentMode === PAYMENT_OPTIONS["2x"];
	const is4xPaymentMode = paymentMode === PAYMENT_OPTIONS["4x"];

	const isLoading =
		usableCouponsStatus === USABLE_CREDITS_STATUS.LOADING_CREDIT_NOTES ||
		usableCouponsStatus === USABLE_CREDITS_STATUS.LOADING_COUPONS;

	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;

	const totalWithCreditFees = is4xPaymentMode ? total + mensualiteCasino.coutCredit : total;

	const totalNode = (
		<Fragment>
			{isMobile && <QuotationItemsContainer />}
			{isMobile && <ExtraPriceDetailsContainer />}
			<div className="price-summary__item">
				<Total
					total={totalWithCreditFees}
					isPagePayment
					isLoading={isLoading}
					paymentMode={paymentMode}
				/>
			</div>
			{isMobile && (
				<div className="price-summary__item">
					<div className="price-summary__price-details">
						<CollapsableQuotationPriceDetailContainerMobile
							isBookingEligibleForDeposit={isBookingEligibleForDeposit}
						/>
					</div>
				</div>
			)}
		</Fragment>
	);

	let payNowNode = false;
	if (is2xPaymentMode && !isLoading) {
		payNowNode = (
			<PayNowContainer
				{...{
					isPaymentPage: true,
					isBookingEligibleForDeposit,
					mensualite2Fois,
				}}
			/>
		);
	} else if (is4xPaymentMode && !isLoading) {
		payNowNode = (
			<div className="pay-now pay-now--payment">
				<div className="pay-now__label">
					<FormattedMessage id="my.bookings.pay.now.label" />
				</div>
				<div className="pay-now__pay-now-amount">
					<AmountContainer amount={mensualiteCasino.apport} />
				</div>
			</div>
		);
	}

	return (
		<React.Fragment>
			<div className="price-summary">
				{totalNode}

				{!is1xPaymentMode && !isLoading && !isMobile && (
					<Separator margin="narrow" design="full" />
				)}

				{!isMobile && payNowNode}
			</div>

			{isMobile && !isLoading && (is2xPaymentMode || is4xPaymentMode) && (
				<div className="price-summary">{payNowNode}</div>
			)}
		</React.Fragment>
	);
};

PaymentPriceSummary.propTypes = {
	total: PropTypes.number,
	isBookingEligibleForDeposit: PropTypes.bool,
	mensualite2Fois: PropTypes.object,
	mensualiteCasino: PropTypes.object,
	paymentMode: PropTypes.number,
	usableCouponsStatus: PropTypes.string,
	resolution: resolutionPropType,
};

export default PaymentPriceSummary;
