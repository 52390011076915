import { connect } from "react-redux";
import {
	calculatePreBookTotal,
	isBookingEligibleForDeposit,
} from "app/pages/Booking/bookingSelectors";
import QuotationPriceSummary from "app/pages/.shared/PriceSummary/QuotationPriceSummary";

const mapStateToProps = state => {
	return {
		hasChildren: state.booking.children > 0,
		isBookingEligibleForDeposit: isBookingEligibleForDeposit(state),
		total: calculatePreBookTotal(state),
		resolution: state.resolution,
	};
};

export default connect(mapStateToProps)(QuotationPriceSummary);
