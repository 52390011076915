import React from "react";
import Popover from "react-popover";
import PropTypes from "prop-types";

class PopoverHover extends React.Component {
	constructor() {
		super();
		this.state = {
			isPopoverOpen: false,
		};
		this.closePopover = this.closePopover.bind(this);
		this.openPopover = this.openPopover.bind(this);
		this.handleBodyClick = this.handleBodyClick.bind(this);
	}

	closePopover() {
		this.setState({
			isPopoverOpen: false,
		});
	}

	openPopover() {
		this.setState({
			isPopoverOpen: true,
		});
	}

	handleBodyClick() {
		if (this.props.closeOnBodyClick) {
			this.setState({
				isPopoverOpen: false,
			});
		}
	}

	render() {
		const {
			buttonLabel = false,
			popOverPosition = "below",
			popOverContent = false,
			popOverClassName,
			popOverButtonClassName,
			tipSize = 7,
		} = this.props;

		return (
			<div
				className={popOverButtonClassName}
				onMouseLeave={this.closePopover}
				onMouseEnter={this.openPopover}
			>
				<Popover
					enterExitTransitionDurationMs={300}
					className={popOverClassName}
					onOuterAction={this.closePopover}
					body={
						<div
							onClick={this.handleBodyClick}
							onMouseLeave={this.closePopover}
							onMouseEnter={this.openPopover}
						>
							{popOverContent}
						</div>
					}
					isOpen={this.state.isPopoverOpen}
					place={popOverPosition}
					tipSize={tipSize}
				>
					<span>{buttonLabel}</span>
				</Popover>
			</div>
		);
	}
}

PopoverHover.propTypes = {
	popOverClassName: PropTypes.string,
	popOverButtonClassName: PropTypes.string,
	popOverPosition: PropTypes.string,
	buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	popOverContent: React.PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	tipSize: PropTypes.number,
	closeOnBodyClick: PropTypes.bool,
};

export default PopoverHover;
