import { createSelector } from "reselect";
import { zonedTimeToUtc } from "date-fns-tz";

const getPreviewStartDate = state => state.merchandising && state.merchandising.previewStartDate;

export const getPushNotifs = state =>
	state.pushNotification && state.pushNotification.pushNotifications;

export const getPushNotifications = createSelector(
	[getPushNotifs, getPreviewStartDate],
	(pushNotifications = [], previewStartDate) => {
		// previewStartDate est valorisé par la query param ?preview=YYYYMMDD
		const previewDateTimestamp = zonedTimeToUtc(previewStartDate || new Date(), "YYYYMMDD");

		return pushNotifications.filter(pushNotification => {
			const pushNotificationEndAtTimestamp = new Date(pushNotification.endAt).getTime();
			const pushNotificationStartAtTimestamp = new Date(pushNotification.launchAt).getTime();
			if (
				previewDateTimestamp &&
				(previewDateTimestamp >= pushNotificationEndAtTimestamp ||
					previewDateTimestamp < pushNotificationStartAtTimestamp)
			) {
				return false;
			}

			return true;
		});
	}
);
