import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Separator from "app/pages/.shared/Separator";
import PayNowContainer from "app/pages/.shared/PayNow/PayNowContainer";
import { CollapsableQuotationPriceDetailContainerMobile } from "app/pages/Booking/Quotation/PriceDetails/QuotationPriceDetailContainer";
import Total from "app/pages/.shared/PriceSummary/Total/Total";
import TotalNote from "app/pages/.shared/PriceSummary/TotalNote/TotalNote";
import "./PriceSummary.scss";
import QuotationItemsContainer from "app/pages/Booking/Quotation/PriceDetails/QuotationItemsContainer";
import ExtraPriceDetailsContainer from "app/pages/Booking/Quotation/PriceDetails/ExtraPriceDetails/ExtraPriceDetailsContainer";
import { resolutionPropType } from "app/utils/propTypes";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";

const QuotationPriceSummary = ({
	hasChildren,
	total,
	isBookingEligibleForDeposit,
	style,
	resolution,
}) => {
	const isMobile = resolution === RESOLUTION.MEDIUM || resolution === RESOLUTION.SMALL;

	return (
		<div className="price-summary" style={style}>
			<Fragment>
				{isMobile && <QuotationItemsContainer />}
				{isMobile && <ExtraPriceDetailsContainer />}
				<div className="price-summary__item">
					<Total total={total} />
				</div>
				<TotalNote showChildrenNote={hasChildren} />
				<div className="price-summary__item">
					<div className="price-summary__price-details">
						<CollapsableQuotationPriceDetailContainerMobile
							isBookingEligibleForDeposit={isBookingEligibleForDeposit}
						/>
					</div>
				</div>
			</Fragment>

			{isBookingEligibleForDeposit && (
				<React.Fragment>
					<Separator margin="narrow" design="full" />
					<PayNowContainer
						{...{
							isPaymentPage: false,
							withDepositIcon: true,
							isBookingEligibleForDeposit,
						}}
					/>
				</React.Fragment>
			)}
		</div>
	);
};

QuotationPriceSummary.propTypes = {
	isBookingEligibleForDeposit: PropTypes.bool,
	total: PropTypes.number,
	style: PropTypes.object,
	hasChildren: PropTypes.bool,
	resolution: resolutionPropType,
};

export default React.memo(QuotationPriceSummary);
