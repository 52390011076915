import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { fetchPushNotifications } from "app/pages/.shared/PushNotification/pushNotificationActionCreators";
import { connect } from "react-redux";
import get from "lodash/get";
import { ConditionalComponents } from "app/pages/.shared/ConditionalComponent/ConditionalComponents";
import { ConditionalComponent } from "app/pages/.shared/ConditionalComponent/ConditionalComponent";
import { PushNotification } from "app/pages/.shared/PushNotification/PushNotification";
import { withRouter } from "react-router-dom";
import { getPushNotifications } from "app/pages/.shared/PushNotification/pushNotificationSelector";
import { Waypoint } from "react-waypoint";

class PushNotificationComponent extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			animate: true,
			hide: false,
		};
		this.onChange = this.onChange.bind(this);
	}

	onChange() {
		const expanded = this.state.expanded;

		this.setState({
			expanded: !expanded,
			animate: false,
		});
	}

	componentDidMount() {
		this.props.fetchPushNotifications();
	}

	render() {
		const { pushNotifications = [], match = {} } = this.props;
		return (
			<React.Fragment>
				<Waypoint
					scrollableAncestor="window"
					bottomOffset={200}
					onEnter={({ previousPosition, currentPosition }) => {
						if (
							previousPosition === Waypoint.below &&
							currentPosition === Waypoint.inside
						) {
							this.setState({
								hide: true,
							});
						}
					}}
					onLeave={({ previousPosition, currentPosition }) => {
						if (
							previousPosition === Waypoint.inside &&
							currentPosition === Waypoint.below
						) {
							this.setState({
								hide: false,
							});
						}
					}}
				/>
				<ConditionalComponents>
					{pushNotifications.map((pushNotification = {}) => {
						const pages = get(pushNotification, "content.pages") || [];

						const checkRoute = pages.find(
							page =>
								match.path.includes(page) ||
								(page === "product" && match.path.includes(":productUri"))
						);

						return (
							<ConditionalComponent
								key={pushNotification.name}
								showIf={() => {
									return checkRoute;
								}}
							>
								<div
									style={{
										transition: "opacity 0.3s ease",
										opacity: this.state.hide ? 0 : 1,
									}}
								>
									<PushNotification
										backgroundColor={get(
											pushNotification,
											"content.backgroundColor"
										)}
										picto={get(pushNotification, "content.picto")}
										title={get(pushNotification, "content.title")}
										content={get(pushNotification, "content.content")}
										promoCode={get(pushNotification, "content.promoCode")}
										note={get(pushNotification, "content.note")}
										buttonUrl={get(pushNotification, "content.buttonUrl")}
									/>
								</div>
							</ConditionalComponent>
						);
					})}
				</ConditionalComponents>
			</React.Fragment>
		);
	}
}

PushNotificationComponent.propTypes = {
	fetchPushNotifications: PropTypes.func,
	pushNotifications: PropTypes.arrayOf(PropTypes.object),
	match: PropTypes.object,
};

const mapStateToProps = state => {
	return {
		pushNotifications: getPushNotifications(state),
	};
};

const mapDispatchToProps = dispatch => {
	return bindActionCreators({ fetchPushNotifications }, dispatch);
};

export const PushNotificationContainer = connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(PushNotificationComponent));
